import React from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'

const About = React.memo(({ content }) => {
	return (
		<div className='page-content'>
			<h1>About page</h1>
			<p>{content[9].content}</p>
			<table dangerouslySetInnerHTML={{__html: content[9].content }} />
		</div>
	)
})

About.propTypes = {
	content: PropTypes.array
}

export default () => (
	<StaticLayout>
		<About />
	</StaticLayout>
)