import React from 'react';
import Icon from '../../img/icon-empty.png'

export default () => (
	<footer>
		<div className='footer-container'>
			<nav>
				<a href='/#programs'>Programs</a>
	      <a href='/#testimonials'>Testimonials</a>
	      <a href='/#about'>About WGI</a>
	      <a href='/#institutions'>Institutions</a>
	      <a href='/#contact'>Contact</a>
			</nav>
			<div className='fc-right'>
				<p>&copy; {new Date().getFullYear()} Washington Global Institute</p>
				<a href='/privacy-policy'>Privacy Policy</a>
			</div>
		</div>
		<img src={Icon} className='icon' alt='WGI icon' />
	</footer>
)