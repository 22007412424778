import React, { useEffect } from 'react';
import { API_IMAGE_PATH } from './_Config'

export default ({ programs, past, scroll }) => {

	useEffect(() => {
		if (scroll > (window.innerHeight * .5)) {
			displayPrograms()
		}
	})

	const displayPrograms = () => {
		const programs = document.querySelectorAll('.program')
		programs.forEach((prog, i) => {
			setTimeout(() => {
				prog.style.opacity = '1'
			}, (i * 500))
		})
	}

	const scrollPrev = () => {
		const cont = document.querySelector('.programs-list')
		const width = document.querySelector('.program').offsetWidth
		if (cont.getAttribute('data-index') === '0') {
			return null
		} else {
			const index = parseInt(cont.getAttribute('data-index')) - 1
			cont.style.marginLeft = width * index * -1 + 'px'
			cont.style.marginRight = width * index + 'px'
			cont.setAttribute('data-index', index)
		}
	}

	const scrollNext = () => {
		const total = programs.length + past.length
		const cont = document.querySelector('.programs-list')
		const width = document.querySelector('.program').offsetWidth
		if (parseInt(cont.getAttribute('data-index')) === total - 2) {
			return null
		} else {
			const index = parseInt(cont.getAttribute('data-index')) + 1
			cont.style.marginLeft = width * index * -1 + 'px'
			cont.style.marginRight = width * index + 'px'
			cont.setAttribute('data-index', index)
		}
	}

	return (
		<div className='programs' id='programs'>
			<div className='container'>
				<h2>Programs</h2>
				<hr />
				<div className='programs-list' data-index='0'>
					{programs.map((prog, i) => {
						return <div className='program' key={i}>
								<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + prog.content[4].content + ')'}} />
								<div className='details'>
									<p className='date'>{prog.date}</p>
									<h3>{prog.content[0].content}</h3>
									<p className='location'>{prog.content[3].content}</p>
									<p className='description' dangerouslySetInnerHTML={{__html: (prog.content[10].content) ? prog.content[10].content : '<p>Click to learn more about this program.</p>'}} />
									<a href={'/program/' + prog.url}>Learn More</a>
								</div>
							</div>
					})}
					{past.map((prog, i) => {
						return <div className='program' key={i}>
								<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + prog.content[4].content + ')'}} />
								<div className='details'>
									<p className='date'>{prog.date}</p>
									<h3>{prog.content[0].content}</h3>
									<p className='location'>{prog.content[3].content}</p>
									<p className='description' dangerouslySetInnerHTML={{__html: (prog.content[10].content) ? prog.content[10].content : '<p>Click to learn more about this program.</p>'}} />
									<a href={'/program/' + prog.url}>Learn More</a>
								</div>
							</div>
					})}
				</div>
				<div className='program-nav'>
					<svg onClick={scrollPrev} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg>
					<a href='/programs' className='see-all'>See All Programs</a>
					<svg onClick={scrollNext} className='pn-next' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg>
				</div>
			</div>
		</div>
	)
}