import React from 'react';

const NavLinks = () => {
  return (
    <div className='nav'>
      <a href='/#programs'>Programs</a>
      <a href='/#institutions'>Institutions</a>
      <a href='/#about'>About</a>
      <a href='/staff'>Guest Speakers</a>
      <a href='/advisory-board'>Advisory Board</a>
      <a href='/#testimonials'>Testimonials</a>
      <a href='/#contact'>Contact</a>
    </div>
  )
}

export default NavLinks