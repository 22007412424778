import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'
import Banner from './_Components/Page-Banner'
import Apply from './_Components/Program-Form'
import Icon from '../img/icon-empty.png'
import { API_IMAGE_PATH } from './_Components/_Config'

const Programs = React.memo(({ content, programs, previous }) => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<div className='page-default'>
			<Banner content={content} scroll={scrollPos} />
			<img src={Icon} className='banner-icon' alt='WGI icon' />
			<div className='container'>
				{/**********************/}
				<h2>{content[2].content}</h2>
				<div className='programs'>
					{programs.map((prog, i) => {
						return <div className='program' key={i}>
							<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + prog.content[4].content + ')'}} />
							<div className='details'>
								<p className='date'>{prog.date}</p>
								<h3>{prog.content[0].content}</h3>
								<p className='location'>{prog.content[3].content}</p>
								<p className='description' dangerouslySetInnerHTML={{__html: prog.content[10].content}} />
								<a href={'/program/' + prog.url}>Learn More</a>
							</div>
						</div>
					})}
				</div>
				{/**********************/}
				<h2>{(previous.length) ? content[3].content : ''}</h2>
				<div className='programs'>
					{previous.map((prog, i) => {
						return <div className='program' key={i}>
							<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + prog.content[4].content + ')'}} />
							<div className='details'>
								<p className='date'>{prog.date}</p>
								<h3>{prog.content[0].content}</h3>
								<p className='location'>{prog.content[3].content}</p>
								<p className='description' dangerouslySetInnerHTML={{__html: prog.content[10].content}} />
								<a href={'/program/' + prog.url}>Learn More</a>
							</div>
						</div>
					})}
				</div>
			</div>
			{/**********************/}
			<div className='cta'>
				<div className='line'><div /><div /><div /><div /></div>
				<h3>{content[4].content}</h3>
				<div className='cta-container'>
					<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[6].content + ')'}} />
					<div className='details'>
						<p>{content[5].content}</p>
						<Apply />
					</div>
				</div>
			</div>
		</div>
	)
})

Programs.propTypes = {
	content: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Programs />
	</StaticLayout>
)