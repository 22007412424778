import React from 'react';

export default ({ content, scroll }) => {

	// useEffect(() => {
	// 	// Sections
	// 	const section = document.querySelectorAll('.section')
	// 	section.forEach((sect, i) => {
	// 		if (scroll > sect.offsetTop - (window.innerHeight * .25)) {
	// 			sect.classList.add('section-active')
	// 		}
	// 	})
	// 	// Lines
	// 	const lines = document.querySelectorAll('.home-about .line')
	// 	lines.forEach((line, i) => {
	// 		if (scroll > line.offsetTop - (window.innerHeight * .75)) {
	// 			line.classList.remove('line-inactive')
	// 		}
	// 	})
	// })

	return (
		<div className='home-institutions' id='institutions'>
			<div className='container'>
				<h2 dangerouslySetInnerHTML={{__html: content[14].content}} />
				<hr />
				<div className='user-text' dangerouslySetInnerHTML={{__html: content[15].content}} />
			</div>
		</div>
	)
}