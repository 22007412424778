import React, { useState } from 'react'
import StaticLayout from './_Components/Layout-Static'
import Submit from './_Components/_API/Submit'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import ContactReducer from './_Redux/_Reducers/contact'

import ConfirmationMessage from './_Components/ConfirmationMessage'
import ContactForm from './_Components/ContactForm'

const Page = React.memo(() => {

	const [ submitted, setSubmitted ] = useState(false)
	const [ firstName, setFirst ] = useState(null)

	const store = createStore(
    ContactReducer,
    window.devToolsExtension && window.devToolsExtension()
  )

	const handleSubmit = inputs => {
		Submit('/contact', inputs)
		setFirst(inputs['2_first_name'])
		setSubmitted(true)
	}

	return (
		<Provider store={store}>
			{submitted
			? <ConfirmationMessage first={firstName} />
			: <ContactForm onFormSubmit={handleSubmit}/>
			}
		</Provider>
	)
})

export default () => (
	<StaticLayout>
		<div className='page-content'>
			<Page />
		</div>
	</StaticLayout>
)