import React, { useEffect } from 'react';
import { API_IMAGE_PATH } from './_Config'

export default ({ content, scroll }) => {

	useEffect(() => {
		// Sections
		const section = document.querySelectorAll('.section')
		section.forEach((sect, i) => {
			if (scroll > sect.offsetTop - (window.innerHeight * .25)) {
				sect.classList.add('section-active')
			}
		})
		// Lines
		const lines = document.querySelectorAll('.home-about .line')
		lines.forEach((line, i) => {
			if (scroll > line.offsetTop - (window.innerHeight * .75)) {
				line.classList.remove('line-inactive')
			}
		})
	})

	return (
		<div className='home-about' id='about'>
			{/*****************/}
			<div className='section section-1' id='about' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[2].content + ')'}}>
				<div className='overlay' />
				<div className='container'>
					<h2 dangerouslySetInnerHTML={{__html: content[3].content}} />
					<hr />
					<div className='user-text' dangerouslySetInnerHTML={{__html: content[4].content}} />
				</div>
			</div>
			<div className='line line-1 line-inactive'>
				<div /><div /><div /><div />
			</div>
			{/*****************/}
			<div className='section section-2' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[5].content + ')'}}>
				<div className='overlay' />
				<div className='container'>
					<h2 dangerouslySetInnerHTML={{__html: content[6].content}} />
					<hr />
					<div className='user-text' dangerouslySetInnerHTML={{__html: content[7].content}} />
				</div>
			</div>
			<div className='line line-2 line-inactive'>
				<div /><div /><div /><div />
			</div>
			{/*****************/}
			<div className='section section-3' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[8].content + ')'}}>
				<div className='overlay' />
				<div className='container'>
					<h2 dangerouslySetInnerHTML={{__html: content[9].content}} />
					<hr />
					<div className='user-text' dangerouslySetInnerHTML={{__html: content[10].content}} />
				</div>
			</div>
			<div className='line line-3 line-inactive'>
				<div /><div /><div /><div />
			</div>
			{/*****************/}
			<div className='section section-4' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[11].content + ')'}}>
				<div className='overlay' />
				<div className='container'>
					<h2 dangerouslySetInnerHTML={{__html: content[12].content}} />
					<hr />
					<div className='user-text' dangerouslySetInnerHTML={{__html: content[13].content}} />
				</div>
			</div>
			<div className='line line-4 line-inactive'>
				<div /><div /><div /><div />
			</div>
		</div>
	)
}