import React from 'react';
import { API_IMAGE_PATH } from './_Config'

export default ({ content, scroll }) => (
	<div className='banner' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[0].content + ')'}}>
		<div className='overlay' />
		<div className='container' style={{'paddingTop': (scroll / 2) + 'px'}}>
			<h1 dangerouslySetInnerHTML={{__html: content[1].content}}/>
			<hr />
		</div>
	</div>
)