import React from 'react';

export default () => {
  
  const hideMenu = () => {
    document.querySelector('.mobile-nav-container').classList.remove('mobile-nav-container-open')
    document.querySelector('.mn-button').classList.remove('mn-button-rvs')
    // Programs
    document.querySelectorAll('.programs-list .program').forEach(pro => { pro.style.opacity = 1 })
    // Sections
    document.querySelectorAll('.section').forEach(sect => { sect.classList.add('section-active') })
    // Lines
    document.querySelectorAll('.line').forEach(lin => { lin.classList.remove('line-inactive') })
  }

  return (
    <div className='mobile-nav-container'>
    	<div className='mnc-background'></div>
      <a href='/' onClick={hideMenu}>Home</a>
      <a href='/#programs' onClick={hideMenu}>Programs</a>
      <a href='/#testimonials' onClick={hideMenu}>Testimonials</a>
      <a href='/#about' onClick={hideMenu}>About WGI</a>
      <a href='/#contact' onClick={hideMenu}>Contact</a>
    </div>
  )
}