import React, { useState, useEffect } from 'react';
import { API_IMAGE_PATH } from './_Config'

export default ({ testimonials, scroll }) => {

	const [ width, setWidth ] = useState(0)
	const [ index, setIndex ] = useState(0)

	useEffect(() => {
		// Set width of each testimonial
		const containerWidth = document.querySelector('.home-testimonials .container').offsetWidth
		setWidth(containerWidth)
		document.querySelectorAll('.home-testimonials .testimonial').forEach(test => {test.style.width = containerWidth + 'px'})
		// Set width of slides parent
		document.querySelector('.home-testimonials .slides').style.width = (testimonials.length * containerWidth) + 'px'
	},[]) //eslint-disable-line

	const scrollPrev = () => {
		const slides = document.querySelector('.home-testimonials .slides')
		if (index === 0) {
			return null
		} else {
			slides.style.marginLeft = width * (index - 1) * -1 + 'px'
			setIndex(index - 1)
		}
	}

	const scrollNext = () => {
		const slides = document.querySelector('.home-testimonials .slides')
		if (index === testimonials.length - 1) {
			return null
		} else {
			slides.style.marginLeft = width * (index + 1) * -1 + 'px'
			setIndex(index + 1)
		}
	}

	return (
		<div className='home-testimonials' id='testimonials'>
			<div className='container'>
				<h2>Testimonials</h2>
				<hr />
				<div className='slides'>
					{testimonials.map((test, i) => {
						return (
							<div className='testimonial' key={i}>
								<div className={(test.images.length > 1) ? 'images' : 'image'}>
									{test.images.map((img, j) => {
										return <img src={API_IMAGE_PATH + img} key={j} /> //eslint-disable-line
									})}
								</div>
								<div className='text'>
									<i class="fas fa-quote-left"></i>
									<div className='user-text' dangerouslySetInnerHTML={{ __html: test.text }} />
								</div>
							</div>
						)
					})}
				</div>
				<div className='nav'>
					<svg onClick={scrollPrev} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg>
					<svg onClick={scrollNext} className='pn-next' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg>
				</div>
			</div>
		</div>
	)
}