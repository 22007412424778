import React, { useState, useEffect } from 'react';
import NavLinks from '../_Components/NavLinks'
import Logo from '../../img/logo.png'
import MobileNavToggle from '../_Components/MobileNavToggle'
import MobileNav from '../_Components/MobileNav'
import Icon from '../../img/icon-empty-rvs.png'

export default () => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<header className={(scrollPos < 150) ? '' : 'header-fixed'}>
			<div className='line'>
				<div /><div /><div /><div />
			</div>
			<div className='header-container'>
				<a href='/' className='logo'>
					<img src={Logo} alt='Washington Global Institute logo' />
				</a>
				<NavLinks />
				<MobileNavToggle />
				<MobileNav />
				<img src={Icon} className='header-icon' alt='WGI icon' style={{'display': (scrollPos < 150) ? 'none' : 'block'}} />
			</div>
		</header>
	)
}