import Home from '../Home'
import Programs from '../Programs'
import Program from '../Program'
import Staff from '../Staff'
import Board from '../Board'
import About from '../About'
import Contact from '../Contact'
import Blog from '../Blog'
import BlogPost from '../BlogPost'
import Events from '../Events'
import EventPost from '../EventPost'
import FourOFour from '../FourOFour'

import Admin from '../Admin'
import AdminSignin from '../Admin-Signin'
import AdminSignout from '../Admin-Signout'
import AdminList from '../Admin-List'
import AdminContent from '../Admin-Content'
import AdminNew from '../Admin-New'
import AdminDelete from '../Admin-Delete'
import AdminMedia from '../Admin-Media'
import AdminQueries from '../Admin-Queries'
import AdminResources from '../Admin-Resources'
import AdminAdmins from '../Admin-Admins'

export default [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/programs",
    exact: true,
    component: Programs
  },
  {
    path: "/program/:id",
    component: Program
  },
  {
    path: "/staff",
    component: Staff
  },
  {
    path: "/advisory-board",
    component: Board
  },
  {
    path: "/about",
    component: About
  },
  {
    path: "/contact",
    component: Contact
  },
  {
    path: "/blog/:id",
    component: BlogPost
  },
  {
    path: "/blog",
    component: Blog
  },
  {
    path: "/event/:id",
    component: EventPost
  },
  {
    path: "/events",
    component: Events
  },
  {
    path: "/admin",
    exact: true,
    component: Admin
  },
  {
    path: "/admin/signin",
    component: AdminSignin
  },
  {
    path: "/admin/signout",
    component: AdminSignout
  },
  {
    path: ["/admin/pages", "/admin/events", "/admin/blog", "/admin/meta", "/admin/alerts", "/admin/programs", "/admin/testimonials", "/admin/board"],
    exact: true,
    component: AdminList
  },
  {
    path: ["/admin/events/new", "/admin/blog/new", "/admin/alerts/new", "/admin/programs/new", "/admin/testimonials/new", "/admin/board/new"],
    exact: true,
    component: AdminNew
  },
  {
    path: ["/admin/events/delete/:id", "/admin/blog/delete/:id", "/admin/alerts/delete/:id", "/admin/programs/delete/:id", "/admin/testimonials/delete/:id", "/admin/board/delete/:id"],
    exact: true,
    component: AdminDelete
  },
  {
    path: ["/admin/pages/:id", "/admin/events/:id", "/admin/blog/:id", "/admin/meta/:id", "/admin/alerts/:id", "/admin/programs/:id", "/admin/testimonials/:id", "/admin/board/:id"],
    component: AdminContent
  },
  {
    path: "/admin/media",
    component: AdminMedia
  },
  {
    path: "/admin/queries",
    component: AdminQueries
  },
  {
    path: "/admin/admins",
    component: AdminAdmins
  },
  {
    path: "/admin/resources",
    component: AdminResources
  },
  {
    path: "*",
    component: FourOFour
  }
]