import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'
import Banner from './_Components/Home-Banner'
import About from './_Components/Home-About'
import Programs from './_Components/Home-Programs'
import Institutions from './_Components/Home-Institutions'
import Testimonials from './_Components/Home-Testimonials'
import Contact from './_Components/Home-Contact'

const Home = React.memo(({ content, programs, past, testimonials }) => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<div className='home'>
			<Banner content={content} scroll={scrollPos} />
			<Programs programs={programs} past={past} scroll={scrollPos} />
			<About content={content} scroll={scrollPos} />
			<Institutions content={content} scroll={scrollPos} />
			<Testimonials testimonials={testimonials} scroll={scrollPos} />
			<Contact />
		</div>
	)
})

Home.propTypes = {
	content: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Home />
	</StaticLayout>
)