import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Admin'

const Queries = React.memo(({ types, queries }) => {

	const [ active, setActive ] = useState(types[0])

	const toggle = (tab) => {
		setActive(tab)
	}

	return (
		<div className='admin-vert-list'>
			<div className='avl-tabs'>
				{types.map((t,i) => {
					return <p key={i} className={(active === t) ? 'avct-active' : ''} onClick={() => toggle(t)}>{t.charAt(0).toUpperCase() + t.substr(1)}</p>
				})}
				<a href='/api/admin/queries/export' target='_blank'>Export All</a>
			</div>
			{queries.map((q,i) => {
				return <div className={(active === q.type) ? 'avl-post avlp-active' : 'avl-post'} key={i}>
					<p>Message from {q.first_name} {q.last_name} on {q.contact_date}:</p>
					<p><em>{q.message}</em></p>
					{q.phone
					? <p>Contact {q.first_name} at {q.email} or {q.phone}.</p>
					: <p>Contact {q.first_name} at {q.email}.</p>
					}
				</div>
			})}
		</div>
	)
})

Queries.propTypes = {
	types: PropTypes.array,
	queries: PropTypes.array
}

export default () => (
	<Layout>
		<Queries />
	</Layout>
)