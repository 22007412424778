import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StaticLayout from './_Components/Layout-Static'
import Apply from './_Components/Program-Form'
import Icon from '../img/icon-empty.png'
import { API_IMAGE_PATH } from './_Components/_Config'

const Programs = React.memo(({ content, date, future }) => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<div className='page-default'>
			{/**********************/}
			<div className='banner' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[4].content + ')'}}>
				<div className='overlay' />
				<div className='container' style={{'paddingTop': (scrollPos / 2) + 'px'}}>
					<h1 dangerouslySetInnerHTML={{__html: content[0].content}}/>
					<hr />
					<p>{content[3].content}</p>
				</div>
			</div>
			<img src={Icon} className='banner-icon' alt='WGI icon' />
			<div className='container'>
				{/**********************/}
				<div className='title'>
					<h2 dangerouslySetInnerHTML={{ __html: content[1].content }} />
					<hr />
					<h3>{content[0].content}</h3>
					<p>{content[3].content}<span>|</span>{date}</p>
				</div>
				{/**********************/}
				<div className='sections'>
					{content[5].content &&
						<div className='program-section focus'>
							<h4>Program Focus</h4>
							<div className='content' dangerouslySetInnerHTML={{ __html: content[5].content }} />
						</div>
					}
					{/**********************/}
					{content[6].content &&
						<div className='program-section pedagogy'>
							<h4>Program Pedagogy</h4>
							<div className='content' dangerouslySetInnerHTML={{ __html: content[6].content }} />
						</div>
					}
					{/**********************/}
					{content[7].content &&
						<div className='program-section logistics'>
							<h4>Logistics</h4>
							<div className='content' dangerouslySetInnerHTML={{ __html: content[7].content }} />
						</div>
					}
					{/**********************/}
					{content[9].content &&
						<div className='program-section hotel'>
							<h4>Hotel Accomodations</h4>
							<div className='content' dangerouslySetInnerHTML={{ __html: content[9].content }} />
						</div>
					}
				</div>
				{/**********************/}
				{content[8].content.replace(/(<([^>]+)>)/ig,"") &&
					<React.Fragment>
						<h5>Program Itinerary</h5>
						<div className='program-itinerary'>
							<table>
								<tbody dangerouslySetInnerHTML={{ __html: content[8].content}} />
							</table>
						</div>
					</React.Fragment>
				}
			</div>
			{/**********************/}
			{future &&
				<div className='cta'>
					<div className='line'><div /><div /><div /><div /></div>
					<h3>Program Inquiry</h3>
					<div className='cta-container'>
						<div className='img' style={{'backgroundImage': 'url(' + API_IMAGE_PATH + content[4].content + ')'}} />
						<div className='details'>
							<p>Are you interested in learning more or participating in the <strong>{content[0].content}</strong> program? Fill out and submit the form below and we'll be in contact with you shortly.</p>
							<Apply input={content[0].content} />
						</div>
					</div>
				</div>
			}
		</div>
	)
})

Programs.propTypes = {
	content: PropTypes.array
}

export default () => (
	<StaticLayout>
		<Programs />
	</StaticLayout>
)