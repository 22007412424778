import React, { useState, useEffect } from 'react'
import Submit from './_API/Submit'

export default React.memo(({ input }) => {
	
	const [ first, setFirst ] = useState('')
	const [ last, setLast ] = useState('')
	const [ program, setProgram ] = useState((input) ? input : '')
	const [ email, setEmail ] = useState('')
	const [ phone, setPhone ] = useState('')
	const [ message, setMessage ] = useState('')
	const [ submit, setSubmit ] = useState(false)
	const [ ip, setIp ] = useState('')
	const [ url, setUrl ] = useState('')

	useEffect(() => {
		(async function getIp(url) {
			try {
		    const response = await fetch(url)
				const json = await response.json()
				setIp(json.ip)
			} catch (error) {
				return null
			}
		})("https://jsonip.com")
	},[])

	const handleSubmit = (e) => {
		e.preventDefault()
		setSubmit(true)
    Submit('/contact', {
    	type: 'program',
    	first,
    	last,
    	program,
    	email,
    	phone,
    	message,
    	ip,
    	url
    })
	}

	return (
		<React.Fragment>
			{submit &&
				<p className='confirm'>Thanks, {first}! We've received your inquiry and will be in touch with you shortly.</p>
			}
			{!submit &&
				<form onSubmit={handleSubmit}>
					<input type='hidden' name='url' value={url} onChange={(e) => setUrl(e.target.value)} />
					<div className='double'>
						<label for='i-first'>First Name
							<input type='text' id='i-first' value={first} onChange={(e) => setFirst(e.target.value)} />
						</label>
						<label for='i-last'>Last Name
							<input type='text' id='i-last' value={last} onChange={(e) => setLast(e.target.value)} />
						</label>
					</div>
					<div className='double'>
						<label for='i-email'>Email
							<input type='email' id='i-email' value={email} onChange={(e) => setEmail(e.target.value)} />
						</label>
						<label for='i-phone'>Phone
							<input type='text' id='i-phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
						</label>
					</div>
					<label for='i-program'>Program(s) Interested In
						<input type='text' id='i-program' value={program} onChange={(e) => setProgram(e.target.value)} />
					</label>
					<label for='i-message'>Additional Information
						<textarea id='i-message' value={message} onChange={(e) => setMessage(e.target.value)} />
					</label>
					<input type='submit' value='Send Inquiry' />
				</form>
			}
		</React.Fragment>
	)
})