import React, { useState, useEffect } from 'react'
import StaticLayout from './_Components/Layout-Static'
import Banner from './_Components/Page-Banner'
import Icon from '../img/icon-empty.png'
import { API_IMAGE_PATH } from './_Components/_Config'

const Staff = React.memo(({ content }) => {

	const [ scrollPos, setScrollPos ] = useState(0)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll())
		return () => {
			window.removeEventListener('scroll', handleScroll())
		}
	})

	const handleScroll = (e) => {
		window.onscroll = function(){
			var st = window.pageYOffset
			setScrollPos(st)
		}
	}

	return (
		<div className='page-default'>
			<Banner content={content} scroll={scrollPos} />
			<img src={Icon} className='banner-icon' alt='WGI icon' />
			<div className='container'>
				{/**********************/}
				<h2>{content[2].content}</h2>
				<div className='staff-list'>
					{content.map((con, i) => {
						if (i > 2) {
							if (con.type !== 'image') {
								return <div className='staff' key={i}>
									<div className='img' style={{ 'backgroundImage': 'url(' + API_IMAGE_PATH + content[i - 1].content + ')'}} />
									<div className='bio' dangerouslySetInnerHTML={{ __html: con.content }} />
									<hr />
								</div>
							} else return null
						} else return null
					})}
				</div>
			</div>
		</div>
	)
})

export default () => (
	<StaticLayout>
		<Staff />
	</StaticLayout>
)