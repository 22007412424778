import React from 'react'
import PropTypes from 'prop-types'
import Layout from './_Components/Layout-Admin'
import Link from './_Components/Admin-Link'
import { useCookies } from 'react-cookie'

const Admin = React.memo(({ role }) => {

	const [ cookies, setCookie ] = useCookies(['signedIn', 'role']) // eslint-disable-line
	
	return (
		<div className='admin-dashboard'>
			<Link title='Pages' desc='Review and edit page content displayed on the website.' link='pages' />
			<Link title='Sign Out' desc='Click to sign out of the administrator dashboard.' link='signout' />
			<Link title='Programs' desc='Review upcoming and past programs, and create new programs.' link='programs' />
			<Link title='Testimonials' desc='Add and edit testimonials' link='testimonials' />
			<Link title='Advisory Board' desc='Add/edit/remove advisory board members' link='board' />
			<Link title='Media' desc='Review and upload new files.' link='media' />
			<Link title='Metadata' desc='The behind-the-scenes context effecting SEO of each web page.' link='meta' />
			<Link title='Resources' desc='Tips and tricks to help you with your website.' link='resources' />
			{role !== 'approved' &&
				<Link title='Admins' desc='View and approve/revoke administrator access.' link='admins' />
			}
			{cookies['role'] === 'super' && 
				<Link title='New Page' desc='For super users, add a new page.' link='new-page' />
			}
		</div>
	)
})

Admin.propTypes = {
	content: PropTypes.array,
	role: PropTypes.string
}

export default () => (
	<Layout>
		<Admin />
	</Layout>
)