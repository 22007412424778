import React, { useState, useEffect } from 'react';
import Submit from './_API/Submit'

export default () => {

	const [ first, setFirst ] = useState('')
	const [ last, setLast ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ phone, setPhone ] = useState('')
	const [ message, setMessage ] = useState('')
	const [ submit, setSubmit ] = useState(false)
	const [ ip, setIp ] = useState('')
	const [ url, setUrl ] = useState('')

	useEffect(() => {
		(async function getIp(url) {
			try {
		    const response = await fetch(url)
				const json = await response.json()
				setIp(json.ip)
			} catch (error) {
				return null
			}
		})("https://jsonip.com")
	},[])

	const handleSubmit = (e) => {
		e.preventDefault()
		setSubmit(true)
    Submit('/contact', {
    	type: 'contact',
    	first,
    	last,
    	email,
    	phone,
    	message,
    	ip,
    	url
    })
	}

	return (
		<div className='home-contact' id='contact'>
			<div className='container'>
				<div className='header'>
					<h2>Contact Us</h2>
					<hr />
				</div>
				<div className='form'>
					{submit &&
						<p className='confirm'>Thanks, {first}! We've received your inquiry and will be in touch with you shortly.</p>
					}
					{!submit &&
						<form onSubmit={handleSubmit}>
							<p>Please contact us to learn more about our programs and how you can get involved. Complete and submit the form below and we'll get in touch with you soon!</p>
							<input type='hidden' name='url' value={url} onChange={(e) => setUrl(e.target.value)} />
							<div className='double'>
								<label for='i-first'>First Name
									<input type='text' id='i-first' value={first} onChange={(e) => setFirst(e.target.value)} />
								</label>
								<label for='i-last'>Last Name
									<input type='text' id='i-last' value={last} onChange={(e) => setLast(e.target.value)} />
								</label>
							</div>
							<div className='double'>
								<label for='i-email'>Email
									<input type='email' id='i-email' value={email} onChange={(e) => setEmail(e.target.value)} />
								</label>
								<label for='i-phone'>Phone
									<input type='text' id='i-phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
								</label>
							</div>
							<label for='i-message'>Message
								<textarea id='i-message' value={message} onChange={(e) => setMessage(e.target.value)} />
							</label>
							<input type='submit' value='Send Message' />
						</form>
					}
				</div>
				<div className='details'>
					{/**********************/}
					<div className='det'>
						<h3>Philippines</h3>
						<p className='name'>Dr. Gary G. Ador Dionisio</p>
						<p>Dean</p>
						<p>School of Diplomacy and Governance</p>
						<p>Email:<a href='mailto:gary.adordionisio@benilde.edu.ph'>gary.adordionisio@benilde.edu.ph</a></p>
						<p>Phone:<a href='tel:09178567868'>09178567868</a></p>
						{/*************/}
						<p className='name'>Ms. Ampy Tibar</p>
						<p>Secretary, Deans's Office</p>
						<p>School of Diplomacy and Governance</p>
						<p>Email:<a href='mailto:amparo.tibar@benilde.edu.ph'>amparo.tibar@benilde.edu.ph</a></p>
						<p>Phone:<a href='tel:82305100'>8230-5100 local 1745 or 1765</a></p>
						{/*************/}
						<p className='name'>Mr. Mark Humprey Mendoza</p>
						<p>Director</p>
						<p>Academic Linkages Office</p>
						<p>Office of the Chancellor</p>
						<p>Email:<a href='mailto:markhumprey.mendoza@benilde.edu.ph'>markhumprey.mendoza@benilde.edu.ph</a></p>
						{/*************/}
						<h3>Peru</h3>
						<p className='name'>Pamela Lastres Dammert</p>
						<p>Latin America Advisor</p>
						<p>Email:<a href='mailto:plastres@washingtonglobalinst.com'>plastres@washingtonglobalinst.com</a></p>
						<p>Phone:<a href='tel:51980123102'>+51.980.123.102</a></p>
					</div>
					{/**********************/}
					<div className='det'>
						<h3>Washington, D.C., USA</h3>
						<p className='name'>Ms. Rosa S. Shad</p>
						<p>Vice President, USA and North America</p>
						<p>Washington Global Institute</p>
						<p>13512 Potomac Riding Lane</p>
						<p>Potomac, Maryland, USA 20850</p>
						<p>Email:<a href='mailto:rosa@washingtonglobalinst.com'>rosa@washingtonglobalinst.com</a></p>
						<p>Phone:<a href='tel:4103032051'>410-303-2051</a></p>
						{/***********/}
						<h3>United Kingdom</h3>
						<p className='name'>Ms. Sanober Killedar</p>
						<p>Vice President, UK and Europe</p>
						<p>Washington Global Institute</p>
						<p>175 Church Hill Road</p>
						<p>Cheam, London, UK SM3 8NE</p>
						<p>Email:<a href='mailto:skilledar@washingtonglobalinst.com'>skilledar@washingtonglobalinst.com</a></p>
						<p>Phone:<a href='tel:07772867189'>07772867189</a></p>
						{/***********/}
						<h3>Zanzibar, Tanzania</h3>
						<p className='name'>Ulrica de Silva</p>
						<p>Africa Advisor</p>
						<p>Email:<a href='mailto:udesilva@washingtonglobalinst.com'>udesilva@washingtonglobalinst.com</a></p>
						<p>Phone:<a href='tel:255777878900'>+255 777 878 900</a></p>
					</div>
				</div>
			</div>
		</div>
	)
}